<template>
  <div>
    <div style="background-color: #f9923167; padding: 10px;">
        <p>
            <strong style="color: darkred;">Important Notice for Upgrades:</strong>
            <br>
            If your <strong>policy was issued before 13 July 2021</strong>, please
            <router-link :to="{ name: 'customerContactUs' }">contact us</router-link> to upgrade your policy or to make
            any policy amendments.
        </p>
    </div>
    <br />
    <p class="title is-5 has-text-centered">Step 1 - Choose your upgrade</p>
    <hr />
    <div class="columns is-multiline">
      <div class="column is-half">Policy Number:</div>
      <div class="column is-half">C2G6283{{ policy.policy.policynum }}</div>
      <div class="column is-half">Policy Type:</div>
      <div class="column is-half">
        {{ policyType }}
      </div>
      <div class="column is-half">Start Date:</div>
      <div class="column is-half">
        {{ $moment(policy.policy.start_date).format('DD/MM/YYYY') }}
      </div>
      <div class="column is-half">End Date:</div>
      <div class="column is-half">
        {{ $moment(policy.policy.end_date).format('DD/MM/YYYY') }}
      </div>

      <div class="column is-half" v-if="!policyType.includes('CRUISE')">Product:</div>
      <div class="column is-half" v-if="!policyType.includes('CRUISE')">
        <b-select v-model="upgrade.product" expanded required>
          <option
            v-for="pr in upgrade.productChoices"
            :key="pr.id"
            :value="pr"
            >{{ $helper.stringCapFirst(pr) }}</option
          >
        </b-select>
      </div>

      <div class="column is-half">
        <!-- COVID-19 Cover: -->
      </div>
      <div class="column is-half yes-covid-cover" v-if="canUpgradeToCovid || policyType.includes('CRUISE')">
        <i class="far status-icon fa-check-circle" /> Includes enhanced Covid-19
        Cover
      </div>
      <div class="column is-half no-covid-cover" v-else>
        <i class="far status-icon fa-times-circle" /> Does not include enhanced
        Covid-19 Cover
      </div>

      <div class="column is-half">Travel Area:</div>
      <div class="column is-half">

        <b-select v-model="upgrade.area" expanded required>
          <option
            v-for="(ar, k) in $c2g.area"
            :key="ar.id"
            :value="k"
            >{{ $helper.stringCapFirst(ar) }}</option
          >
        </b-select>
      </div>

      <!-- <div class="column is-half">Endorsements:</div>
      <div class="column is-half">
          {{ upgrade.endorsements }}
      </div> -->
    </div>

    <button class="button is-info is-fullwidth" @click="nextStage">
      Continue
    </button>

    <br>

     <button class="button is-primary is-fullwidth" @click="showCovers = !showCovers">
      View Summary of Cover
    </button>

    <hr>

    <template v-if="showCovers">
      <div class="columns">
          <div class="column"></div>
          <div class="column title-box">Platinum</div>
          <div class="column title-box">Gold</div>
          <div class="column title-box">Silver</div>
      </div>

      <!-- Initial -->
      <div class="columns" v-for="cover in initialCovers" :key="cover.id">
          <template>
              <div class="column name-box">
                  <b-tooltip position="is-right" size="is-large" multilined :style="{cursor: 'pointer'}">
                      {{ cover['covername'] }}
                      <template v-slot:content>
                          <div v-html="cover['coverdesc']"></div>
                      </template>
                  </b-tooltip>
              </div>
              <div class="column cost-box" v-html="cost(cover['platinum_limit'])"/>
              <div class="column cost-box" v-html="cost(cover['gold_limit'])"/>
              <div class="column cost-box" v-html="cost(cover['silver_limit'])"/>
          </template>    
      </div>
      <template v-if="showExtraCovers">
        <div class="columns" v-for="cover in JSON.parse(covers)" :key="cover.id">
            <template v-if="!initialCoversNames.includes(cover['covername'])">
                <div class="column name-box">
                    <b-tooltip position="is-right" size="is-large" multilined :style="{cursor: 'pointer'}">
                        {{ cover['covername'] }}
                        <template v-slot:content>
                            <div v-html="cover['coverdesc']"></div>
                        </template>
                    </b-tooltip>
                </div>
                <div class="column cost-box" v-html="cost(cover['platinum_limit'])"/>
                <div class="column cost-box" v-html="cost(cover['gold_limit'])"/>
                <div class="column cost-box" v-html="cost(cover['silver_limit'])"/>
            </template>    
        </div>
      </template>  
      <button class="button is-primary is-fullwidth" @click="showExtraCovers = !showExtraCovers">Show Other Covers</button>  
    </template>

</div>
</template>

<script>
export default {
  name: 'CustomerUpgradeForm',
  data() {
    return {
      policyType: '',
      productChoices: null,
      showCovers: false,
      showExtraCovers: false,
      covers: '{}',
      skiCovers: '{}',
      cruiseCovers: '{}',
      initialCovers: [],
      initialCoversNames: ['Main Excess', 'Cancellation / Curtailment', 'Emergency Medical Expenses', 'Return Journey - Denied Boarding (cover for additional accommodation)', 'Return Journey - Denied Boarding (cover for additional return transportation)', 'Gadget', 'Personal possessions']
    };
  },
  props: ['policy', 'upgrade'],
  methods: {
    nextStage() {
      this.upgrade.step = 2;
    },
    cost(v) {
        return (v === 'N/A') ? '<i class="fas fa-times" style="color: red"></i>' : v.includes('&pound;') ? v : ('&pound;' + v);
    }
  },
  computed: {
    canUpgradeToCovid() {
      // const covid19 = this.policy.policy.covid19;
      // const currentProduct = this.policy.policy.policy_product;
      const upgradeProduct = this.upgrade.product;
      return upgradeProduct === 'gold' || upgradeProduct === 'platinum';
    },
  },
  created() {
    // const type = this.$route.query.type;
    this.$c2g.getCoverLimits('WHERE ski = 0 AND cruise = 0').then(res => {
        this.covers = res.data;

        // Initial covers
        const covers1 = JSON.parse(this.covers);
        this.initialCoversNames.forEach(coverName => {
            this.initialCovers.push(covers1.filter(c => c['covername'] === coverName)[0]);
        });
        // console.log(covers1);
    });

    this.$c2g.getEndorsements(this.policy.quote.type).then((response) => {
        Object.entries(response.data).forEach((v) => {
            let dbName = v[0];
            if (this.policy.endorsements[dbName] === 'Yes' && dbName === 'cruise') {
                this.policyType = `CRUISE - ${this.$c2g.policyType[this.policy.quote.type].toUpperCase()}`;
                this.upgrade.productChoices = [ 'Cruise' ]
            }
        });
    });
  }
};
</script>

<style lang="scss" scoped>
.yes-covid-cover {
  .fa-check-circle {
    color: green;
  }
}
.no-covid-cover {
  color: red;
}
.title-box {
  // background-color: $c2g_blue;
  // color: white;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-left: 8px;
  font-weight: bold;
}
.top-cost-box {
  border-top-left-radius: 10px;
}
.cost-box {
  text-align: center;
  background-color: white;
  margin-left: 8px;
  border-bottom: 2px solid #ebebeb;
  border-right: 1px solid #ebebeb;
  border-left: 1px solid #ebebeb;
}
.name-box {
  background-color: white;
  border-bottom: 2px solid #ebebeb;
  font-weight: bold;
}
/deep/ {
  .tooltip-content {
      min-width: 650px;
      text-align: left !important;
  }
}
</style>
